<!-- 新增弹窗 -->
<template>
  <div class="">
    <el-dialog
      :title="title"
      :visible.sync="visible"
      width="30%"
      @close="closeModal"
      :close-on-click-modal="false"
      z-index="5"
    >
      <ax-form ref="form" :formBuilder="formBuilder">
        <aximage slot="pic" :number="6"></aximage>
      </ax-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="visible = false">取 消</el-button>
        <el-button
          type="primary"
          @click="submit"
          :loading="saveType"
          style="background-color: #556bff"
          >确 认</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
    
    <script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import api from "../api";
import aximage from "@/pages/views/Acomponents/ax-image-modal/src/image-upload.vue";
const formList = [
  {
    label: "店铺名称",
    type: "select",
    model: "name",
    options: { placeholder: "请选择" },
    formItem: {},
    col: { span: 24 },
    rules: [{ required: true, message: "请选择" }],
  },
  {
    label: "商品名称",
    type: "",
    model: "pcode",
    options: { placeholder: "请输入" },
    formItem: {},
    col: { span: 24 },
    rules: [{ required: true, message: "请输入" }],
  },
  {
    label: "单价",
    type: "inputNumber",
    model: "address",
    options: { placeholder: "请输入"},
    formItem: {},
    col: { span: 24 },
    rules: [{ required: true, message: "请输入" }],
  },
  {
    label: "商品图片",
    type: "",
    model: "pic",
    options: { placeholder: "请输入"},
    formItem: {},
    col: { span: 24 },
    rules: [{ required: true, message: "请输入" }],
  },
  {
    label: "商品类型",
    type: "select",
    model: "graphList",
    options: { placeholder: "请输入"},
    formItem: {},
    col: { span: 24 },
    rules: [{ required: true, message: "请输入" }],
  },
  {
    label: "商品状态",
    type: "",
    model: "graphList",
    options: {disabled:true},
    formItem: {},
    col: { span: 24 },
    rules: [{ required: true, message: "请输入" }],
  },
  {
    label: "是否上架",
    type: "switch",
    model: "switch",
    options: {},
    formItem: {},
    col: { span: 1 },
    rules: [],
  },
  {
    label: "商品详情",
    type: "textarea",
    model: "address",
    options: { minRows: 4, maxRows: 4 , placeholder: "请输入"},
    formItem: {},
    col: { span: 24 },
    rules: [{ required: true, message: "请输入" }],
  },
];
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {aximage},
  data() {
    //这里存放数据
    return {
      api,
      visible: false,
      saveType:false,
      title: "",
      formBuilder: this.$common.initGridFormData(
        formList,
        { layout: "horizontal", labelWidth: 130 },
        { col: { span: 8 } }
      ),
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    // 打开弹窗
    openModal(record) {
      this.visible = true;
      this.title = record.titles
      if (record.titles === '新增商品') {
        this.$nextTick(() => {
        this.$refs.form.setOptions(["graphList"], "hide", true);
        this.$refs.form.setOptions(["switch"], "hide", true);
      });
      }else {
      this.$nextTick(() => {
        this.$refs.form.setOptions(["graphList"], "hide", false);
        this.$refs.form.setOptions(["switch"], "hide", false);
      });
      }
    },

    closeModal() {
      this.visible = false;
    },
    submit(){

    }
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
    <style lang='less' scoped>
/deep/.el-dialog {
  border-radius: 20px !important;
}
/deep/.ant-switch-checked {
  background-color: #2a5efe !important;
}
/deep/.ant-switch {
  background-color: #e6eaed;
}
</style>