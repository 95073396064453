<!-- 审核弹窗 -->
<template>
  <div class="">
    <el-dialog
      :title="title"
      :visible.sync="visible"
      width="50%"
      @close="closeModal"
      :close-on-click-modal="false"
    >

      <!-- 描述列表 -->
      <a-descriptions  style="
        word-break: break-word;
        word-wrap: break-word;
        white-space: pre-wrap;
      " layout="vertical">
        <a-descriptions-item label="店铺名称"> 张三 </a-descriptions-item>
        <a-descriptions-item label="商品名称"> 1810000000 </a-descriptions-item>
        <a-descriptions-item label="单价">2 </a-descriptions-item>
        <a-descriptions-item label="商品图片" span="3">
          <imagePreview ref="img1" :businessId="businessId"></imagePreview>
        </a-descriptions-item>
        <a-descriptions-item label="商品类型"> 张三 </a-descriptions-item>
        <a-descriptions-item label="销量"> 1810000000 </a-descriptions-item>
        <a-descriptions-item label="商品状态">2 </a-descriptions-item>
        <a-descriptions-item label="商品详情" span="3">
       123211212331213123123123123123123123123123123123123123123123123123123123123
       123211212331213123123123123123123123123123123123123123123123123123123123123
       123211212331213123123123123123123123123123123123123123123123123123123123123
       123211212331213123123123123123123123123123123123123123123123123123123123123
        </a-descriptions-item>
      </a-descriptions>
      <!-- footer -->
      <span slot="footer" class="dialog-footer">
        <el-button
          @click="visible = false"
          type="primary"
          style="background-color: #556bff"
          >返 回</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
  
  <script>
import imagePreview from "@/pages/views/Acomponents/image-preview/imagePreview";
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

export default {
  //import引入的组件需要注入到对象中才能使用
  components: { imagePreview },
  data() {
    //这里存放数据
    return {
      visible: false,
      businessId: "",
      title: "详情",
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    // 打开弹窗
    openModal(record = {}) {
      this.visible = true;
    },

    closeModal() {
      this.visible = false;
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
  <style lang='less' scoped>
/deep/.el-dialog {
  border-radius: 20px !important;
}
/deep/.el-dialog__title {
  color: #2d3138;
}
/deep/.ant-descriptions-view {
  padding-left: 1%;
}
/deep/.ant-descriptions-title {
  font-size: 0.08rem;
  border-left: 4px solid #2A5CFF;
  padding-left: 0.5%;
  line-height: 100%;
}

/deep/.ant-descriptions-item-label {
  color: #7d8da6 !important;
  padding-left: 4px;
}

/deep/.ant-descriptions-item-content {
  color: #2d3138;
}
.titleBox {
  display: flex;
  align-items: center;
  font-size: 0.08rem;
  font-weight: 700;
  color: #2d3138;
  margin-bottom: 10px;
  .titleLine {
    height: 0.09rem;
    width: 0.015rem;
    background-color: #2a5cff;
    margin-right: 5px;
  }
}
</style>