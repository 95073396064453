<!--商品管理 -->
<template>
  <div>
    <ax-table
      ref="tableBox"
      :columns="columns"
      :show-search="true"
      :searchForm="searchForm"
      :toolActions="toolActions"
      @add="add"
      :showToolBar="true"
      @action-column-click="actionColumnClick"
      :dynamicSlotNames="['gridId']"
      :dataSourceApi="api.list"
      :dataSourceParams="dataSourceParams"
      :scroll="{ y: '55vh', x: '80vw' }"
    >
      <!-- 上架 -->
      <a-switch v-model="record.status" slot="status" slot-scope="{ record }" />
      <!-- 状态 -->
      <div slot="flag" slot-scope="{ record }">
        <a-button
          :class="{
            gray: record.flag === '2', //已驳回
            green: record.flag === '1', //已通过
            orange: record.flag === '0', //待审核
          }"
        >
          {{
            record.flag === "0"
              ? "待审核"
              : record.flag === "1"
              ? "已通过"
              : "已驳回"
          }}
        </a-button>
      </div>
      <!-- 图片 -->
      <el-image
      slot="pic"
        style="width: 150px; height: 80px"
        src="https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg"
        :preview-src-list="srcList"
        fit="scale-down"
      ></el-image>
    </ax-table>
    <!-- 添加弹窗 -->
    <addCommodity ref="addStore"></addCommodity>
    <!-- 详情弹窗 -->
     <detaila ref="detaila"></detaila>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
const searchForm = [
  {
    label: "商品名称",
    type: "input",
    model: "typeName",
    options: { placeholder: "请输入" },
    formItem: {},
    col: { span: 6 },
  },
  {
    label: "店铺名称",
    type: "input",
    model: "typeName",
    options: { placeholder: "请输入" },
    formItem: {},
    col: { span: 6 },
  },
  {
    label: "商品状态",
    type: "input",
    model: "typeName",
    options: { placeholder: "请选择" },
    formItem: {},
    col: { span: 6 },
  },
  {
    label: "商品类型",
    type: "select",
    model: "typeNames",
    options: { placeholder: "请选择" },
    formItem: {},
    col: { span: 6 },
  },
];
import api from "./api";
import addCommodity from "./components/addCommodity.vue";
import detaila from "./components/details.vue";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: { addCommodity,detaila },
  data() {
    //这里存放数据
    return {
      api,
      // 表格模块数据
      columns: this.$ax.tools.initColumn(
        [
          {
            title: "商品名称",
            dataIndex: "parentTypeName",
            ellipsis: true,
            width: 120,
          },
          {
            title: "店铺名称",
            dataIndex: "typeName",
            ellipsis: true,
            width: 100,
          },
          {
            title: "单价",
            dataIndex: "referScore",
            ellipsis: true,
            width: 80,
          },
          {
            title: "商品类型",
            dataIndex: "createBy1",
            ellipsis: true,
            width: 120,
          },
          {
            title: "商品图片",
            dataIndex: "pic",
            ellipsis: false,
            width: 150,
          },
          {
            title: "销量",
            dataIndex: "createBy",
            ellipsis: true,
            width: 80,
          },
          {
            title: "商品状态",
            dataIndex: "flag",
            ellipsis: false,
            width: 120,
          },
          {
            title: "是否上架",
            dataIndex: "status",
            ellipsis: false,
            width: 150,
          },
        ],
        false,
        {
          width: 180,
          // 操作部分
          actions: [
            {
              props: (record) => {
                return {
                  text: "编辑",
                  name: "edit",
                  type: "#2A5CFF",
                  link: true,
                  popDisabled: true,
                  // disabled: record.isAbandon === "1",
                };
              },
            },
            {
              props: (record) => {
                return {
                  text: "详情",
                  name: "detail",
                  type: "#E6A23C",
                  link: true,
                  popDisabled: true,
                  // disabled: record.isAbandon === "1",
                };
              },
            },
            {
              props: (record) => {
                return {
                  text: "删除",
                  name: "delete",
                  type: "#FA5959",
                  link: true,
                  popDisabled: true,
                  // disabled: record.isAbandon === "1",
                };
              },
            },
          ],
        }
      ),
      searchForm: this.$common.initGridFormData(searchForm, {
        layout: "horizontal",
        labelWidth: 100,
      }),
      toolActions: [{ name: "add", text: "新增" }],
      dataSourceParams: {},
      srcList: ['https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',]
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    // 表格操作
    async actionColumnClick(args) {
      const {
        btn: { name },
        record,
      } = args;
      switch (name) {
        // 编辑
        case "edit":
          this.$refs.addStore.openModal({ ...record, titles: "编辑商品" });
          break;
           // 详情
        case "detail":
          this.$refs.detaila.openModal();
          break;
      }
    },

    add() {
      this.$refs.addStore.openModal({ titles: "新增商品" });
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang='less' scoped>
.ant-switch-checked {
  background-color: #2a5efe !important;
}
.ant-switch {
  background-color: #e6eaed;
}
.orange {
  background-color: #fff8f0;
  color: #e6a23c;
  border: #fff8f0;
  border-radius: 4px;
}
.green {
  background-color: #ebf9e6;
  color: #67c23a;
  border-radius: 4px;
  border: #ebf9e6;
}
.gray {
  background-color: #e6e9ee;
  color: #2d3138;
  border-radius: 4px;
  border: #e6e9ee;
}
</style>